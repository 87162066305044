import React from 'react';

const PrivacyPolicy: React.FC = () => {
    return (
        <div className="max-w-4xl mx-auto py-8 px-4 sm:px-6 lg:px-8 text-gray-100">
            <h1 className="text-3xl font-bold mb-6">Privacy Policy</h1>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">1. Introduction</h2>
                <p>
                    At Great Idea Technology, we respect your privacy and are committed to protecting your personal data.
                    This privacy policy will inform you about how we look after your personal data when you visit our website
                    and tell you about your privacy rights and how the law protects you.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">2. The Data We Collect</h2>
                <p>
                    We collect and process the following data when you use our idea submission form:
                </p>
                <ul className="list-disc list-inside mt-2">
                    <li>Name</li>
                    <li>Email address</li>
                    <li>Idea title</li>
                    <li>Idea description</li>
                    <li>Current stage of your idea</li>
                    <li>Technology support needed</li>
                </ul>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">3. How We Use Your Data</h2>
                <p>
                    We use the data you provide solely for the purpose of evaluating your idea submission and contacting you
                    regarding potential collaboration. We do not use your data for marketing purposes or share it with third parties.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">4. Data Storage and Security</h2>
                <p>
                    Your submitted ideas and personal information are stored securely. We implement appropriate technical and
                    organizational measures to ensure a level of security appropriate to the risk.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">5. Your Rights</h2>
                <p>
                    Under data protection laws, you have rights in relation to your personal data that include the right to:
                </p>
                <ul className="list-disc list-inside mt-2">
                    <li>Request access to your personal data</li>
                    <li>Request correction of your personal data</li>
                    <li>Request erasure of your personal data</li>
                    <li>Object to processing of your personal data</li>
                    <li>Request restriction of processing your personal data</li>
                    <li>Request transfer of your personal data</li>
                    <li>Withdraw consent</li>
                </ul>
                <p className="mt-4">
                    <strong>Right to object:</strong> You have the right to object to our processing of your personal data. This effectively allows you to ask us to stop processing your personal data. This right applies when we are processing your personal data based on our legitimate interests. You can object by contacting us using the details provided below, explaining your objection and the processing you are objecting to. However, in some cases, we may be able to continue processing if we can demonstrate compelling legitimate grounds which override your interests.
                </p>
                <p className="mt-4">
                    If you wish to exercise any of the rights set out above, please contact us at <a href="mailto:privacy@gi-t.com" className="text-blue-400 hover:underline">privacy@gi-t.com</a>.
                </p>
            </section>

            <section className="mb-8">
                <h2 className="text-2xl font-semibold mb-4">6. Changes to the Privacy Policy</h2>
                <p>
                    We may update our privacy policy from time to time. We will notify you of any changes by posting the new
                    privacy policy on this page.
                </p>
            </section>

            <section>
                <h2 className="text-2xl font-semibold mb-4">7. Contact Us</h2>
                <p>
                    If you have any questions about this privacy policy, our data practices, or you would like to exercise one of your data protection rights, please don't hesitate to contact us at:
                </p>
                <p className="mt-2">
                    <a href="mailto:privacy@gi-t.com" className="text-blue-400 hover:underline">privacy@gi-t.com</a>
                </p>
            </section>
        </div>
    );
};

export default PrivacyPolicy;