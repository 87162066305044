import React from 'react';
import {BrowserRouter as Router, Route, Link, Routes} from 'react-router-dom';
import { Mail } from 'lucide-react';
import Home from './Home';
import About from './About';
import OurApproach from './OurApproach';
import Contact from './Contact';
import PrivacyPolicy from "./PrivacyPolicy";
import Header from './Header';

const Footer: React.FC = () => (
    <footer className="p-5 text-center bg-gray-800 text-white">
        <div className="flex justify-center items-center mb-2">
            <Mail size={20} className="mr-2" />
            <a href="mailto:contact@gi-t.com" className="hover:underline">contact@gi-t.com</a>
        </div>
        <p>&copy; 2024 Great Idea Technology. All rights reserved.</p>
        <p>www.gi-t.com</p>
        <Link to="/privacy-policy" className="text-blue-400 hover:underline">Privacy Policy</Link>
    </footer>
);

const LandingPage: React.FC = () => {
    return (
        <Router>
            <div className="min-h-screen bg-gray-900 text-white flex flex-col">
                <Header />

                <main className="flex-grow flex flex-col items-center justify-center px-4 py-8">
                    <div className="w-full max-w-4xl mx-auto">
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/about" element={<About/>}/>
                            <Route path="/our-approach" element={<OurApproach/>}/>
                            <Route path="/contact" element={<Contact/>}/>
                            <Route path="/privacy-policy" element={<PrivacyPolicy/>}/>
                        </Routes>
                    </div>
                </main>

                <Footer />
            </div>
        </Router>
    );
};

export default LandingPage;
