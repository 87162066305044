import React from 'react';
import { Info, Shield } from 'lucide-react';
import { Link } from 'react-router-dom';

const ResponseProcess: React.FC = () => (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg max-w-2xl mx-auto text-left text-gray-100">
        <h2 className="text-2xl font-bold mb-4 flex items-center">
            <Info className="mr-2 text-blue-400" />
            Our Response Process
        </h2>
        <ul className="list-disc list-inside space-y-2">
            <li>We carefully review all submitted ideas, but due to the volume of submissions, we may not be able to respond to every proposal.</li>
            <li>If your idea aligns with our current focus and capabilities, we will contact you within 15 business days.</li>
            <li>Selected ideas will receive a detailed follow-up, including potential next steps and a proposal for collaboration.</li>
            <li>Even if we don't proceed with your idea, we appreciate your initiative and trust in sharing it with us.</li>
            <li>We may keep your idea on file for future consideration, as our focus areas and capabilities evolve over time.</li>
        </ul>
        <div className="mt-6">
            <h3 className="text-xl font-bold mb-2 flex items-center">
                <Shield className="mr-2 text-green-400" />
                Data Privacy and GDPR Compliance
            </h3>
            <ul className="list-disc list-inside space-y-2">
                <li>We are committed to protecting your personal data and complying with GDPR regulations.</li>
                <li>Your submitted information will be used solely for the purpose of evaluating your idea and contacting you about potential collaboration.</li>
                <li>We retain your data for a maximum of 12 months, after which it will be automatically deleted from our systems.</li>
                <li>You have the right to request the removal of your data at any time by contacting us at privacy@gi-t.com.</li>
                <li>For more details on how we handle your data, please refer to our <Link to="/privacy-policy" className="text-blue-400 hover:underline">Privacy Policy</Link>.</li>
            </ul>
        </div>
        <p className="mt-4 text-sm italic">
            Please note: We treat all submitted ideas with strict confidentiality. However, we recommend you don't share highly sensitive technical details in your initial submission.
        </p>
    </div>
);

export default ResponseProcess;