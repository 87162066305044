import React, { useState, FormEvent } from 'react';

interface FormData {
    name: string;
    email: string;
    ideaTitle: string;
    description: string;
    stage: string;
    techSupport: string;
}

interface FormErrors {
    name?: string;
    email?: string;
    ideaTitle?: string;
    description?: string;
    stage?: string;
    techSupport?: string;
}

const IdeaSubmissionForm: React.FC = () => {
    const [formData, setFormData] = useState<FormData>({
        name: '',
        email: '',
        ideaTitle: '',
        description: '',
        stage: '',
        techSupport: ''
    });

    const [errors, setErrors] = useState<FormErrors>({});
    const [submitMessage, setSubmitMessage] = useState('');

    const validateForm = (): boolean => {
        const newErrors: FormErrors = {};

        if (!formData.name.trim()) {
            newErrors.name = 'Name is required';
        }

        if (!formData.email.trim()) {
            newErrors.email = 'Email is required';
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Email is invalid';
        }

        if (!formData.ideaTitle.trim()) {
            newErrors.ideaTitle = 'Idea title is required';
        }

        if (formData.description.trim().length < 50) {
            newErrors.description = 'Description should be at least 50 characters long';
        }

        if (!formData.stage) {
            newErrors.stage = 'Please select a stage';
        }

        if (formData.techSupport.trim().length < 20) {
            newErrors.techSupport = 'Tech support description should be at least 20 characters long';
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
        // Clear the error for this field when the user starts typing
        if (errors[name as keyof FormErrors]) {
            setErrors(prevErrors => ({
                ...prevErrors,
                [name]: undefined
            }));
        }
    };

    const generateEmailContent = (): string => {
        return `
Subject: New Idea Submission: ${formData.ideaTitle}

Dear Great Idea Technology Team,

I would like to submit a new idea for your consideration:

Name: ${formData.name}
Email: ${formData.email}
Idea Title: ${formData.ideaTitle}

Description:
${formData.description}

Current Stage: ${formData.stage}

Technology Support Needed:
${formData.techSupport}

Thank you for your time and consideration. I look forward to hearing from you.

Best regards,
${formData.name}
    `.trim();
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (validateForm()) {
            const emailContent = generateEmailContent();

            navigator.clipboard.writeText(emailContent).then(() => {
                setSubmitMessage('Email content copied to clipboard! Please paste this into a new email and send it to contact@gi-t.com');
            }, (err) => {
                console.error('Could not copy text: ', err);
                setSubmitMessage('Error copying email content. Please manually copy the content below and send it to contact@gi-t.com');
            });
        } else {
            setSubmitMessage('Please correct the errors in the form before submitting.');
        }
    };

    return (
        <form onSubmit={handleSubmit} className="space-y-6 text-gray-100">
            <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-200">Name</label>
                <input
                    type="text"
                    id="name"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    className={`mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 placeholder-gray-400
            shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50
            ${errors.name ? 'border-red-500' : 'border-gray-600'}`}
                />
                {errors.name && <p className="mt-1 text-sm text-red-400">{errors.name}</p>}
            </div>

            <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-200">Email</label>
                <input
                    type="email"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    className={`mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 placeholder-gray-400
            shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50
            ${errors.email ? 'border-red-500' : 'border-gray-600'}`}
                />
                {errors.email && <p className="mt-1 text-sm text-red-400">{errors.email}</p>}
            </div>

            <div>
                <label htmlFor="ideaTitle" className="block text-sm font-medium text-gray-200">Idea Title</label>
                <input
                    type="text"
                    id="ideaTitle"
                    name="ideaTitle"
                    value={formData.ideaTitle}
                    onChange={handleChange}
                    className={`mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 placeholder-gray-400
            shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50
            ${errors.ideaTitle ? 'border-red-500' : 'border-gray-600'}`}
                />
                {errors.ideaTitle && <p className="mt-1 text-sm text-red-400">{errors.ideaTitle}</p>}
            </div>

            <div>
                <label htmlFor="description" className="block text-sm font-medium text-gray-200">Description</label>
                <textarea
                    id="description"
                    name="description"
                    value={formData.description}
                    onChange={handleChange}
                    rows={4}
                    className={`mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 placeholder-gray-400
            shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50
            ${errors.description ? 'border-red-500' : 'border-gray-600'}`}
                ></textarea>
                {errors.description && <p className="mt-1 text-sm text-red-400">{errors.description}</p>}
            </div>

            <div>
                <label htmlFor="stage" className="block text-sm font-medium text-gray-200">Current Stage</label>
                <select
                    id="stage"
                    name="stage"
                    value={formData.stage}
                    onChange={handleChange}
                    className={`mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100
            shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50
            ${errors.stage ? 'border-red-500' : 'border-gray-600'}`}
                >
                    <option value="">Select stage</option>
                    <option value="Concept">Concept</option>
                    <option value="Prototype">Prototype</option>
                    <option value="Early Product">Early Product</option>
                    <option value="Growth Phase">Growth Phase</option>
                </select>
                {errors.stage && <p className="mt-1 text-sm text-red-400">{errors.stage}</p>}
            </div>

            <div>
                <label htmlFor="techSupport" className="block text-sm font-medium text-gray-200">Technology Support Needed</label>
                <textarea
                    id="techSupport"
                    name="techSupport"
                    value={formData.techSupport}
                    onChange={handleChange}
                    rows={3}
                    className={`mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-gray-100 placeholder-gray-400
            shadow-sm focus:border-indigo-500 focus:ring focus:ring-indigo-500 focus:ring-opacity-50
            ${errors.techSupport ? 'border-red-500' : 'border-gray-600'}`}
                ></textarea>
                {errors.techSupport && <p className="mt-1 text-sm text-red-400">{errors.techSupport}</p>}
            </div>

            <button
                type="submit"
                className="w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
                Generate Email Content
            </button>

            {submitMessage && (
                <div className={`mt-4 p-4 rounded ${submitMessage.includes('Error') ? 'bg-red-900 text-red-200' : 'bg-green-900 text-green-200'}`}>
                    {submitMessage}
                </div>
            )}
        </form>
    );
};

export default IdeaSubmissionForm;
