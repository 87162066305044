import React, { useState } from 'react';
import { ChevronDown } from 'lucide-react';
import IdeaSubmissionForm from './IdeaSubmissionForm';
import ResponseProcess from './ResponseProcess';

const Home: React.FC = () => {
    const [showForm, setShowForm] = useState(false);
    const [showProcess, setShowProcess] = useState(false);

    return (
        <div className="text-center">
            <h1 className="text-4xl font-bold mb-4">Welcome to Great Idea Technology</h1>
            <p className="text-xl mb-8">We turn visionary ideas into technological realities.</p>
            <p className="text-lg mb-8 max-w-2xl mx-auto">
                Unlike traditional incubators, we provide hands-on technology support to bring selected ideas to life.
                Think of us as your dedicated CTO, committed to your success with skin in the game.
            </p>

            <div className="space-y-4 mb-8">
                <button
                    onClick={() => setShowForm(!showForm)}
                    className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center mx-auto"
                >
                    {showForm ? 'Hide Form' : 'Submit Your Idea'}
                    <ChevronDown size={20} className={`ml-2 transform ${showForm ? 'rotate-180' : ''} transition-transform duration-200`} />
                </button>
                <button
                    onClick={() => setShowProcess(!showProcess)}
                    className="bg-gray-700 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded flex items-center mx-auto"
                >
                    {showProcess ? 'Hide Process' : 'Our Response Process'}
                    <ChevronDown size={20} className={`ml-2 transform ${showProcess ? 'rotate-180' : ''} transition-transform duration-200`} />
                </button>
            </div>

            {showForm && <IdeaSubmissionForm />}
            {showProcess && <ResponseProcess />}

            <div className="text-sm mt-8 bg-gray-800 p-4 rounded-lg max-w-2xl mx-auto">
                <p className="mb-2"><strong>Selected ideas receive:</strong></p>
                <ul className="list-disc list-inside">
                    <li>Expert technology support and development resources</li>
                    <li>Hands-on guidance from experienced technologists</li>
                    <li>Access to our network of industry professionals</li>
                </ul>
                <p className="mt-4">We invest our expertise for equity, aligning our success with yours.</p>
            </div>
        </div>
    );
};

export default Home;