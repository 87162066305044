import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { AtomIcon, Menu, X } from 'lucide-react';

const Logo = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" className="h-8 w-8 mr-2">
        <circle cx="50" cy="50" r="48" fill="#3B82F6" />
        <path d="M30 50 L50 20 L70 50 L50 80 Z" fill="white" />
        <line x1="20" y1="50" x2="80" y2="50" stroke="white" strokeWidth="2" />
        <line x1="50" y1="20" x2="50" y2="80" stroke="white" strokeWidth="2" />
        <circle cx="50" cy="50" r="15" fill="none" stroke="white" strokeWidth="2" />
    </svg>
);

const Header: React.FC = () => {
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const location = useLocation();

    const toggleMenu = () => setIsMenuOpen(!isMenuOpen);

    const isActive = (path: string) => location.pathname === path;

    return (
        <header className="bg-gray-800 text-white">
            <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex items-center justify-between h-16">
                    <div className="flex-shrink-0">
                        <Link to="/" className="flex items-center">
                            <Logo />
                            <span className="font-bold text-xl">Great Idea Technology</span>
                        </Link>
                    </div>
                    <div className="hidden md:block">
                        <div className="ml-10 flex items-baseline space-x-4">
                            <Link
                                to="/about"
                                className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/about') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                            >
                                About
                            </Link>
                            <Link
                                to="/our-approach"
                                className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/our-approach') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                            >
                                Our Approach
                            </Link>
                            <Link
                                to="/contact"
                                className={`px-3 py-2 rounded-md text-sm font-medium ${isActive('/contact') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                            >
                                Contact
                            </Link>
                        </div>
                    </div>
                    <div className="md:hidden">
                        <button
                            onClick={toggleMenu}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-white hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-800 focus:ring-white"
                        >
                            <span className="sr-only">Open main menu</span>
                            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>
                </div>
            </div>

            {/* Mobile menu, show/hide based on menu state */}
            {isMenuOpen && (
                <div className="md:hidden">
                    <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
                        <Link
                            to="/about"
                            className={`block px-3 py-2 rounded-md text-base font-medium ${isActive('/about') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                        >
                            About
                        </Link>
                        <Link
                            to="/our-approach"
                            className={`block px-3 py-2 rounded-md text-base font-medium ${isActive('/our-approach') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                        >
                            Our Approach
                        </Link>
                        <Link
                            to="/contact"
                            className={`block px-3 py-2 rounded-md text-base font-medium ${isActive('/contact') ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white'}`}
                        >
                            Contact
                        </Link>
                    </div>
                </div>
            )}
        </header>
    );
};

export default Header;