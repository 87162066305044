import React from 'react';

const OurApproach: React.FC = () => {
    return (
        <div className="max-w-2xl mx-auto">
            <h1 className="text-3xl font-bold mb-4">Our Approach</h1>
            <p className="mb-4">
                At Great Idea Technology, we take a hands-on approach to turning innovative ideas into successful businesses.
                Here's how we work:
            </p>
            <ol className="list-decimal list-inside mb-4">
                <li className="mb-2">Idea Submission: We review all submitted ideas carefully.</li>
                <li className="mb-2">Selection: We choose ideas with the most potential for technological innovation and market success.</li>
                <li className="mb-2">Partnership: We become your technological co-founder, offering our expertise and resources.</li>
                <li className="mb-2">Development: Our team works closely with you to develop and refine your product.</li>
                <li className="mb-2">Launch: We support you through the launch phase, helping to bring your product to market.</li>
                <li>Growth: We continue to provide technological support as your business grows and evolves.</li>
            </ol>
            <p>
                Our goal is to be more than just an incubator. We're your dedicated technology partner, invested in your success
                and committed to turning your great idea into a thriving business.
            </p>
        </div>
    );
};

export default OurApproach;