import React from 'react';

const About: React.FC = () => {
    return (
        <div className="max-w-2xl mx-auto">
            <h1 className="text-3xl font-bold mb-4">About Great Idea Technology</h1>
            <p className="mb-4">
                Great Idea Technology is a unique incubator that bridges the gap between innovative ideas and technological realization.
                We're not just advisors; we're your partners in bringing groundbreaking concepts to life.
            </p>
            <p className="mb-4">
                Our team consists of experienced technologists who are passionate about
                nurturing the next big ideas in technology. With years of hands-on experience
                in various tech domains, we bring practical knowledge and insights to every project we undertake.
            </p>
            <p>
                At Great Idea Technology, we believe that with the right technical expertise and support,
                any great idea can become a successful reality. We're here to provide that support and
                be your technological co-founder on the journey from concept to market success.
            </p>
        </div>
    );
};

export default About;