import React from 'react';

const Contact: React.FC = () => {
    return (
        <div className="max-w-2xl mx-auto">
            <h1 className="text-3xl font-bold mb-4">Contact Us</h1>
            <p className="mb-6">
                We're always excited to hear about new ideas and potential partnerships.
                You can reach us through the email provided in the footer of this page.
            </p>
            <p className="mb-4">
                Whether you have a question about our process, want to submit an idea, or are interested in partnering with us,
                we're here to help. Don't hesitate to reach out!
            </p>
            <p>
                Our team of experienced technologists is ready to evaluate your ideas and provide
                the technical expertise needed to bring them to life. We look forward to hearing from you
                and potentially collaborating on the next big innovation in technology.
            </p>
        </div>
    );
};

export default Contact;